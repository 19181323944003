<template>
  <div class="wrapper">
  
    <div v-if="showSearchPanel">

      
      <div class="weeklyChart" v-if="routineTemplateId && weeklyChartValues && iconLibrary">
        <v-sparkline
          
          :smooth="16"
          color="white"
          :line-width="3"
          :value="weeklyChartValues"
          auto-draw
          stroke-linecap="round"
        ></v-sparkline>
      </div>
      

      <div class="heedarHeader" v-else>
        <div class="agendaTitle">
          <div class="agendaTitleDate">
            {{dayOfWeekName}}
          </div>
        </div>
      </div>


      <ul id="weeklyResultList">
        <li v-for="(results, index) in sortedWeeklyResultsTotals" v-bind:key="'weeklyResult'+index" :id="'weeklyResult'+index" class="listItem">
          <div class="itemWrapper">
            <div class="weeklyResultItem" v-on:click="expandDetail({results, index})">
              <div class="currentWeekLabel" v-if="index == 0">
                Current Week
              </div>
              <div class="currentWeekLabel" v-if="index == 1">
                Last Week
              </div>
              <div class="weeklyHeader">

                <div class="weeklyHeaderTitle">
                  {{ results.dayStamp }} - {{ endOfWeekDayStamp[index] }}
                </div>

              </div>
              
              
              <div v-for="(totalObj, actionTitle, index2) in results['data']['weeklyTotals']" v-bind:key="index2">
                <div class="weekHolder">
                  <div class="iconHolder" v-if="iconLibrary && iconLibrary[ actionTitle ]" >
                    <img :src="iconLibrary[ actionTitle ].iconGrey" class="icon">
                  </div>
                  <div v-else class="iconSpacer">
                    &nbsp;
                  </div>
                  <div class="actionItemHolder">
                    {{ (actionTitle.substring(0,2) == 'do') ? actionTitle.slice(3) : actionTitle }}  &nbsp;&nbsp; <span style="font-size: 2em;color: white;"><strong>{{ totalObj.total }}</strong></span><span style="font-size: .9em;font-weight: 700;"> {{ (totalObj.uom) ? totalObj.uom : 'times' }}</span>
                  </div>
                  <div class="paceGap" v-if="index == 0 && pacingData[actionTitle]">
                    <div v-if="pacingData[actionTitle]['gap'] > 0" class="greenGap">
                      +{{ pacingData[actionTitle]['gap'] }}
                    </div>
                    <div v-else-if="pacingData[actionTitle]['gap'] < 0" class="redGap">
                      {{ pacingData[actionTitle]['gap'] }}
                    </div>
                  </div>
                  <div class="paceLabel" v-if="(index == 0) && (index2 == 0)">
                    Pacing vs This<br>Time Last Week
                  </div>
                </div>
              </div>


                <!--
                <div class="expandChevron" :id="`expandChevron`+index">
                  <i v-if="!weeklyResultItemStatusArray[index]" class="fas fa-chevron-down"></i>
                  <i v-else class="fas fa-chevron-up"></i>
                </div>
                -->
            </div>
            

            <transition name="fade">
              <div class="routineSearchItemDetail" 
                :id="'routineSearchItemDetail'+index" 
                v-if="weeklyResultItemStatusArray[index]"
              >

                <div class="routineDetailWrapper">
                  
                  
                </div>
              </div>
            </transition>
          </div>
          
        </li>
      </ul>
    </div>

    


  </div>
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    //import feelingModule from '@/store/feeling'
    //import { mapGetters, mapActions } from 'vuex'
    //import '@/plugins/vuetify'
    //import MevChains from '@/components/MevChains'

    import outcomeModule from '@/store/outcome'
    //import routineModule from '@/store/routine'
    
    
    //import Worker from "worker-loader!../worker";
    import { mapGetters, mapActions } from 'vuex'
    import moment from 'moment-timezone'
    //import { VSelect } from 'vuetify/lib'

  export default {
    name: "ActionWake",
    components: {
      
    },
    props: {
      parentView: {
        type: String
      },
      searchString: {
        type: String
      },
      routineTemplateId: {
        type: String
      },
      displayMode: {
        type: String
      }
    },
    data () {
      return {
        showSearchPanel: true,
        routineList: [],
        weeklyResultItemStatusArray: [],
        showRequestRoutineForm: false,

        routineSchedules: {},
        routinePeriodSchedules: {},
        routinePeriodActions: {},
        daysOfWeekArray: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        searchScoreArray: [],


        filter: null,

        requestText: null,


        
      }
    },
    computed: {
      ...mapGetters('auth', {
        profile: 'profile',
        loggedIn: 'loggedIn',
        role: 'role',
      }),
      ...mapGetters('outcome', [
        'actionWakeHappinessScores',
        'actionWakeOutcomeReports',
        'actionWakeWorkSessionItems'
      ]),
      ...mapGetters('routine', [
        'iconLibrary',
        'userRoutines'
      ]),
      dayOfWeekName () {
        return moment().format('dddd') + `, `+ moment().format('Y')+ `-`+moment().format('MM')+`-`+moment().format('DD')
      },

      endOfWeekDayStamp () {
        if(this.sortedWeeklyResultsTotals) {
          var endOfWeekDayStamps = []
          this.sortedWeeklyResultsTotals.forEach((_, index) => {

            endOfWeekDayStamps.push(moment(this.sortedWeeklyResultsTotals[index].dayStamp).endOf('isoWeek').format("YYYY-MM-DD"))
            
          })
          return endOfWeekDayStamps
        }else{
          return null
        }
      },

      checkWorkSessions () {
        if(this.userRoutines.length > 0) {
          var check = this.userRoutines.find(routine => { return routine.routineType == 'work sessions'})
          if(check){
            return true
          }else{
            return false
          }
        }else{
          return false
        }
      },

      momentTitle () {
        if(this.userRoutines.length > 0 && this.routineTemplateId) {
          var routine = this.userRoutines.find(routine => {return routine.routineTemplateId == this.routineTemplateId})
          var momentTitle = (routine.routineMoments[0].actionAttributes ? routine.routineMoments[0].actionAttributes.agendaItemTitle : routine.routineMoments[0].momentTitle)
          return momentTitle
        }else{
          return null
        }
      },
      weeklyChartValues () {
        if(this.weeklyResultsTotals) {
          var array = []
          //var title = this.momentTitle

          Object.keys(this.weeklyResultsTotals).forEach(key => {
            if(key != 'yearlyTotals' && this.weeklyResultsTotals[key]['weeklyTotals'][this.momentTitle]) {
              var total = this.weeklyResultsTotals[key]['weeklyTotals'][this.momentTitle]['total']
              array.push(total)
            }
            
          })
          array.reverse()

          return array
        }else{
          return null
        }
      },

      //Old computed variables
      resultList () {
        if((this.routineList.length > 0) && this.filter) {
          
          var newFilter = this.filter
          
          var resultArray = []
          var routineListArray = []
          var scoreArray = []

          //determine a score for each routine
          this.routineList.forEach((routine) => {
            var searchStringArray, filterStringArray
            var searchString = ""
            var title = (routine.rt) ? routine.rt.toUpperCase().trim() : ""
            var tags = (routine.t) ? routine.t.toUpperCase().trim() : ""
            var summary = (routine.rs) ? routine.rs.toUpperCase().trim() : ""
            var type = (routine.rty) ? routine.rty.toUpperCase().trim() : ""
            var code = (routine.rc) ? routine.rc.toUpperCase().trim() : ""
            var intentMean = (routine.im) ? routine.im.toUpperCase().trim() : ""
            var level = (routine.l) ? routine.l.toUpperCase().trim() : ""

            searchString += title + " "
            searchString += tags + " "
            searchString += summary + " "
            searchString += type + " "
            searchString += code + " "
            searchString += intentMean + " "
            searchString += level + " "
            searchString = searchString.trim()
            var score = 0
            //split the searchString, put into word array, searchStringArray
            searchStringArray = searchString.split(" ")
            //split this.filter up into a word array, filterStringArray
            filterStringArray = newFilter.toUpperCase().split(" ")
            //forEach word in filterStringArray, check if the searchStringArray contains that word, if it does, increment score by 1
            filterStringArray.forEach(word => {
              if(searchStringArray.includes(word)) {
                ++score
              }
            })
            //check if filter string has a direct match in the searchString
            if(searchString.indexOf(newFilter.toUpperCase()) > -1) {
              ++score
            }
            //create a score array
            scoreArray.push(score)
            //clone this.routineList into another array
            routineListArray.push(routine)
          })
          
          //combine the routineList with the score array
          var combinedRoutineScoreArray = []
          routineListArray.forEach((routine,idx) => {
            var obj = {
              ...routine,
              'score': scoreArray[idx]
            }
            combinedRoutineScoreArray.push(obj)
          })

          //sort by score, desc
          combinedRoutineScoreArray.sort((a,b) => {
            return (b.score - a.score)
          })

          resultArray = combinedRoutineScoreArray.filter(routine => {
            return routine.score > 0
          })

          /*
          var resultArray2 = null
          if(this.daysOfWeekFilter && ((this.daysOfWeekFilter != `-- Filter Off --`)&&(this.daysOfWeekFilter != 'Filter by days per week'))) {
            resultArray2 = resultArray.filter(routine => {
              return routine.t.includes(this.daysOfWeekFilter)
            })
          }else{
            resultArray2 = resultArray
          }
          */

          return resultArray.slice(0,40)     //puts a limit of forty on the results

        }else{
          return []
        }
      },
      tagCloudsArray () {
        if(this.filteredResultList.length > 0) {
          var tagCloudsArray = []
            this.filteredResultList.forEach(async (routine) => {
              //split tags by comma
              var splitTagArray = routine.t.split(",")
              var cleanArray = splitTagArray.map((tag) => {
                var str = tag.trim()
                
                  return str
                
              })
              cleanArray.pop()
              tagCloudsArray.push(cleanArray)
            })
            return tagCloudsArray
        } else {
          return []
        }
      },
      filteredResultList () {
        var clone = this.resultList
        if((this.resultList.length > 0) && (this.daysOfWeekFilter) && (this.daysOfWeekFilter != "Filter by days per week") && (this.daysOfWeekFilter != `-- Filter Off --`)) {
          

          var filteredResults = clone.filter(routine => {
            if(routine.t) {
              var upper = routine.t.toUpperCase()
              return upper.indexOf(this.daysOfWeekFilter.toUpperCase()) > -1
            }else{
              return clone.slice(0,14)
            }
            
          })

          return filteredResults.slice(0,14)     //puts a limit of fourteen on the results
          
        }else{
          return clone.slice(0,14)
        }
      },
      //Old computed variables

      


      weeklyResultsObj () {
        if(this.actionWakeOutcomeReports) {

          var weeklyArrays = {}
          var weeklyByMomentTitle = {}


          //Outcomes will go into one object where data is placed into weekly array buckets
          Object.keys(this.actionWakeOutcomeReports).forEach(key => {
            Object.keys(this.actionWakeOutcomeReports[key].outcomeLog).forEach(key2 => {
              var begin = moment(this.actionWakeOutcomeReports[key].outcomeLog[key2].dayStamp).startOf('isoWeek').format("YYYY-MM-DD");
              //Filter outcomes by routineId
              if(this.routineTemplateId) {
                if(this.actionWakeOutcomeReports[key].outcomeLog[key2].routineTemplateId == this.routineTemplateId) {
                  //var weekNum = `Week `+Math.ceil(this.actionWakeOutcomeReports[key].outcomeLog[key2].daysInRoutine / 7)
                  if(!weeklyArrays[begin]) {
                    weeklyArrays[begin] = []
                  }
                  weeklyArrays[begin].push(this.actionWakeOutcomeReports[key].outcomeLog[key2])
                }
              }else{
                
                if(!weeklyArrays[begin]) {
                  weeklyArrays[begin] = []
                }
                weeklyArrays[begin].push(this.actionWakeOutcomeReports[key].outcomeLog[key2])
              }
            })
          })
          

          //For each array bucket, group by routineTemplateId or moment title
          Object.keys(weeklyArrays).forEach(weekNumKey => {
            weeklyArrays[weekNumKey].forEach(outcomeObj => {
              if(!weeklyByMomentTitle[weekNumKey]) {
                weeklyByMomentTitle[weekNumKey] = {}
              }
              if(!weeklyByMomentTitle[weekNumKey][outcomeObj.dayStamp]) {
                weeklyByMomentTitle[weekNumKey][outcomeObj.dayStamp] = {}
              }
              if(!weeklyByMomentTitle[weekNumKey][outcomeObj.dayStamp][outcomeObj.momentTitle]) {
                weeklyByMomentTitle[weekNumKey][outcomeObj.dayStamp][outcomeObj.momentTitle] = []
              }
              weeklyByMomentTitle[weekNumKey][outcomeObj.dayStamp][outcomeObj.momentTitle].push(outcomeObj)
            })
          })

          return weeklyByMomentTitle

        }else{
          return null
        }

      },



      weeklyResultsTotals () {
        function getSum(total, num) {
            return total + Math.round(num);
          }
        if(this.weeklyResultsObj) {
          var weeklyByMomentTitleTotals = {}

          //Tally
          var yearlyTotal = {}
          Object.keys(this.weeklyResultsObj).forEach(key3 => {
            var weeklyTotal = {}
            
            Object.keys(this.weeklyResultsObj[key3]).forEach(key4 => {
              /* eslint-disable no-unused-vars */
              Object.keys(this.weeklyResultsObj[key3][key4]).forEach(titleKey => {
                
                /* eslint-disable no-console */
                var type = this.weeklyResultsObj[key3][key4][titleKey][0].routineType
                //console.log("type", type)
                var outcomeValueTotals = []

                var rti = this.weeklyResultsObj[key3][key4][titleKey][0].routineTemplateId
                
                this.weeklyResultsObj[key3][key4][titleKey].forEach(outcomeObj => {

                  if(type == 'exercise') {
                    var total = 0
                    outcomeObj.outcomes.forEach(out => {
                      if(out.stepType === 'action') {
                        total += out.stepValueA
                      }
                    })
                    outcomeValueTotals.push(total)
                    
                  }else if ( 
                    (type == 'exercise2') || 
                    (type == 'generic') 
                    ) {
                      
                      outcomeValueTotals.push(outcomeObj.outcomeActionStepValueATotal)

                  }else if ( 
                    (type == 'cessation') || 
                    (type == 'everyday habit') ||
                    (type == 'work sessions') ||
                    (type == 'cooking')
                    ) {
                      outcomeValueTotals.push(outcomeObj.outcomeActionStepValueTotal)
                  }

                  
                })

                if(!weeklyByMomentTitleTotals[key3]) {
                  weeklyByMomentTitleTotals[key3] = {}
                }
                if(!weeklyByMomentTitleTotals[key3][key4]) {
                  weeklyByMomentTitleTotals[key3][key4] = {}
                }
                if(!weeklyByMomentTitleTotals[key3][key4][titleKey]) {
                  weeklyByMomentTitleTotals[key3][key4][titleKey] = {}
                }
                
                if( 
                    (type == 'exercise') ||
                    (type == 'exercise2') || 
                    (type == 'generic') ||
                    (type == 'cessation') || 
                    (type == 'everyday habit') ||
                    (type == 'work sessions') ||
                    (type == 'cooking')
                  ){
                  var sum = outcomeValueTotals.reduce(getSum, 0)
                  //console.log("sum", sum)
                  
                  var uom = null
                  
                    uom = (this.weeklyResultsObj[key3][key4][titleKey][0].outcomes.length > 0) ? this.weeklyResultsObj[key3][key4][titleKey][0].outcomes[0].stepValueUom : null
                  
                  
                  weeklyByMomentTitleTotals[key3][key4][titleKey]['dailyTotal'] = sum
                  if(!weeklyTotal[titleKey]) {
                    weeklyTotal[titleKey] = {
                      'uom': uom,
                      'total': 0,
                      'routineTemplateId': rti,
                    }
                  }
                  weeklyTotal[titleKey]['total'] += sum
                  
                  if(!yearlyTotal[titleKey]) {
                    yearlyTotal[titleKey] = 0
                  }
                  yearlyTotal[titleKey] += sum

                }

              })
              
            })
            weeklyByMomentTitleTotals[key3]['weeklyTotals'] = weeklyTotal
            
          })
          weeklyByMomentTitleTotals['yearlyTotals'] = yearlyTotal


          return weeklyByMomentTitleTotals

        }else{
          return null
        }
        
      },

      sortedWeeklyResultsTotals () {
        if(this.weeklyResultsTotals) {
          var array = []
          Object.keys(this.weeklyResultsTotals).forEach(key => {
            var results = this.weeklyResultsTotals[key]
            var timestamp = moment(key).valueOf()
            var obj = {
              'timeStamp': timestamp,
              'dayStamp': key,
              'data': {
                ...results,
              }
            }
            array.push(obj)
          })

          array.sort((a,b) => {
            if(a.timeStamp < b.timeStamp) {
              return -1
            }
            if(a.timeStamp > b.timeStamp) {
              return 1
            }
            return 0
          })

          array.reverse()

          array.shift()   //this removes the first element, which are yearlytotals not needed here

          return array


        }else{
          return null
        }
      },



      pacingData () {
        function getSum(total, num) {
          return total + Math.round(num);
        }
        if(this.weeklyResultsObj && this.weeklyResultsTotals) {

          var thisWeekDayStamp = moment().startOf('isoWeek').format("YYYY-MM-DD");
          var lastWeekDayStamp = moment().startOf('isoWeek').subtract(1, 'weeks').format("YYYY-MM-DD");
          var thisWeekOutcomesObj = this.weeklyResultsObj[thisWeekDayStamp]
          var lastWeekOutcomesObj = this.weeklyResultsObj[lastWeekDayStamp]
          var pacingDataObj = {}

          Object.keys(thisWeekOutcomesObj).forEach(dayStamp6 => { //For each day
            Object.keys(thisWeekOutcomesObj[dayStamp6]).forEach(actionTitle => {
              if(!pacingDataObj[actionTitle]) {
                pacingDataObj[actionTitle] = {
                  'thisWeek': [],
                  'lastWeek': [],
                  'gap': null,
                }
              }
                thisWeekOutcomesObj[dayStamp6][actionTitle].forEach(outcome => {
                  pacingDataObj[actionTitle]['thisWeek'].push(outcome)
                })
            })
          })

          Object.keys(lastWeekOutcomesObj).forEach(dayStamp => { //For each day
            Object.keys(lastWeekOutcomesObj[dayStamp]).forEach(actionTitle => {
              if(pacingDataObj[actionTitle]) {
                lastWeekOutcomesObj[dayStamp][actionTitle].forEach(outcome => {
                  pacingDataObj[actionTitle]['lastWeek'].push(outcome)
                })
              }
            })
          })

          //console.log('pacingDataObj - yo', pacingDataObj)
          var timeNow = moment().valueOf()
          var startOfWeekTime = moment().startOf('isoWeek').valueOf()
          var startOfLastWeekTime = moment().startOf('isoWeek').subtract(1, 'weeks').valueOf()
          var diff = timeNow - startOfWeekTime
          var lastWeekTimePoint = startOfLastWeekTime + diff
          //console.log('lastWeekTimePoint', lastWeekTimePoint)

          Object.keys(pacingDataObj).forEach(actionTitle => {

            //Get this week totals for each actionTitle from the weeklyResultsTotals object
            var thisWeekTotal = this.weeklyResultsTotals[thisWeekDayStamp]['weeklyTotals'][actionTitle]['total']

            //console.log('thisWeekTotal', thisWeekTotal)

            var lastWeekToThisTimeOutcomes = null
            
            lastWeekToThisTimeOutcomes = pacingDataObj[actionTitle]['lastWeek'].filter(out => { 
              return  (out.momentStartTime <= lastWeekTimePoint) 
            })
       


            //console.log("lastWeekToThisTimeOutcomes", lastWeekToThisTimeOutcomes)

            var lastWeekOutcomeValueTotals = []
            lastWeekToThisTimeOutcomes.forEach(outcomeObj => {
              if(outcomeObj.routineType == 'exercise') {
                var total = 0
                outcomeObj.outcomes.forEach(out => {
                  if(out.stepType === 'action') {total += out.stepValueA}
                })
                lastWeekOutcomeValueTotals.push(total)
              }else if ( (outcomeObj.routineType == 'exercise2') || (outcomeObj.routineType == 'generic') ) {  
                lastWeekOutcomeValueTotals.push(outcomeObj.outcomeActionStepValueATotal)
              }else if ( (outcomeObj.routineType == 'cessation') || (outcomeObj.routineType == 'everyday habit') || (outcomeObj.routineType == 'work sessions') ||(outcomeObj.routineType == 'cooking')) {
                lastWeekOutcomeValueTotals.push(outcomeObj.outcomeActionStepValueTotal)
              }

            })

            var lastWeekTotal = lastWeekOutcomeValueTotals.reduce(getSum, 0)
            var gap = thisWeekTotal - lastWeekTotal
            pacingDataObj[actionTitle]['gap'] = gap

          })


          return pacingDataObj

        }else{
          return null
        }


        
      },


      mergedResultsObj () {
        if(this.weeklyResultsObj && !this.routineId && this.checkWorkSessions) {

          //this component is for the full action wake with work session items merged with weeklyResultsObj


          return

        }else{
          return null
        }

      },



      


    },
    watch: {

      resultList: {
        deep: true,
        handler: async function (newVal) {
          if(newVal) {         //This getTheData pattern skips the first iteration and process the rest
            if((newVal.length == 0) && (this.showSearchPanel)) {
              this.showRequestRoutineForm = true
            }else{
              this.showRequestRoutineForm = false
            }
          }
        }
      },

      /*  
      searchString: {
        deep: true,
        handler: async function (newVal) {
          if(newVal) {         //This getTheData pattern skips the first iteration and process the rest
            //document.getElementById("mySearch").value = this.searchString
            //document.getElementById("mySearch").blur()
            //this.myFunction2()
          }
        }
      }
      */

    },
    methods: {
      
      ...mapActions('auth', [
        'setEditMode'
      ]),
      ...mapActions('outcome', [
        'getActionWakeHappinessData',
        'getActionWakeOutcomeData',
        'getActionWakeWorkSessionData'
      ]),
      
      initSearchPanel () {
        //this.showSearchPanel=true;
        return;
      },

      filterInput () {
        if(this.daysOfWeekFilter === '-- Filter Off --') {
          this.daysOfWeekFilter = 'Filter by days per week'
        }
      },

     



      requestRoutine () {
        this.$router.push(`/routine/request/`+this.filter)
      },

      async initializeStatuses () {
        return new Promise((resolve) => {
          if(this.routineList.length > 0) {
            //console.log("you got mail")
            var weeklyResultItemStatusArray = []
            this.routineList.forEach(async () => {   
              weeklyResultItemStatusArray.push('false')
            })
            this.weeklyResultItemStatusArray = weeklyResultItemStatusArray
            resolve()
          }
        })
      },
      
      myFunction2 () {
        var input = document.getElementById("mySearch");
        if(input.value.length < 1) {
          this.showSearchPanel = false
          this.filter = null 
          this.routineSearchItemStatusArray = []
          return
        }
        this.filter = input.value
        //this.$nextTick(() => {
          this.showSearchPanel = true
        //})

      },
      


      expandDetail (payload) {
        if(!this.weeklyResultItemStatusArray[payload.index]) {
          this.$set(this.weeklyResultItemStatusArray, payload.index, true)




        }else{
          this.$set(this.weeklyResultItemStatusArray, payload.index, false)
        }
      },

      

    },
    beforeCreate () {
      if(!this.$store.state.outcome) this.$store.registerModule('outcome', outcomeModule)
      
    },
    async created () {
      
      this.initializeStatuses()

      /* eslint-disable no-console */
      if(!this.actionWakeHappinessScores) {
        this.getActionWakeHappinessData()
      }
      if(!this.actionWakeOutcomeReports) {
        this.getActionWakeOutcomeData()
      }
      if(!this.actionWakeWorkSessionItems) {
        this.getActionWakeWorkSessionData()
      }
    
     
      




    
    },
    mounted () {

    

      
      
      
    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
.card {
  padding: 20px;
  min-height: 500px;
  color: black;
}
.noSee {
  display: none;
}
.fade-enter-active, .fade-leave-active {
  transition: all .1s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-10%);
}

.wrapper {
  width: 100%;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.97);
  overflow: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}



#weeklyResultList  {
  list-style: none;
  padding-left: 0;
  max-width: 600px;
  margin: 0 auto;
}
#weeklyResultList > li {
  width: 100%;
  border-top: 1px solid rgb(100, 100, 100);
  background-color: #131313;
}
#weeklyResultList > li:last-child {

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.weeklyChart {
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
}


.weeklyResultItem {
  font-family: 'Quicksand', sans-serif;
  font-size: 1em;
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  position: relative;
  padding-top: 10px;
  padding-bottom: 12px;
  color: rgb(194, 194, 194);
  overflow: visible;

}
.currentWeekLabel {
  position: absolute;
  top: -8px;
  left: 0;
  font-size: .9em;
}
.weeklyHeader {
  margin-bottom: 40px;
}
.weeklyHeaderTitle {
  font-size: .9em;
  color: white;
}
.weekHolder {
  position: relative;
  margin-top: 0;
  margin: 0 auto;
  background-color: transparent;
  width: 95%;
  min-height: 80px;
  margin-bottom: 14px;
  overflow: visible;
}
.iconHolder {
  position: absolute;
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
  margin-left: 0px;
  background-color: transparent;
  left: -5px;
  top: 10px;

}
.iconSpacer {
  width: 38px;
  height: 50px;
  display: inline-block;
}
.actionItemHolder {
  position: absolute;
  display: inline-block;
  background-color: transparent;
  bottom: 25px;
  left: 40px;
  z-index: 5;
}
.paceGap {
  position: absolute;
  display: inline-block;
  background-color: transparent;
  right: 37px;
  top: 3%;
  font-size: 1.5em;
  
}

.paceLabel {
  position: absolute;
  text-align: center;
  display: inline-block;
  background-color: transparent;
  right: 18px;
  top: -73px;
  font-size: .8em;
}
.greenGap {
  color: rgb(116, 216, 107);
}
.redGap {
  color: rgb(255, 155, 155);
}
.icon {
  width: 40px;
  opacity: 0.7;
}
.expandChevron {
  position: absolute;
  top: 10px;
  right: 0;
  cursor: pointer;
}
.itemWrapper {
  padding: 15px;
}

.agendaTitleDate {
  color:#8a8a8a;
  font-size: .90em;
  font-weight: 600;
  padding-top: 2px;
  font-size: 1.05em;
}
.agendaTitleText {
  background-color: transparent;
  color:#8a8a8a;
  font-weight: 400;
}
.heedarHeader {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  padding: 15px;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  background-color: transparent;
  
}


@media screen and (min-width: 320px) {
  .weekHolder {
    width: 100%;
    transform: translateX(7%);
  }
  

}



@media screen and (min-width: 360px) {
  .weekHolder {
    width: 93%;
  }

}


.actionItemHolder {
    left: 55px;
  }




@media screen and (min-width: 375px) {

  .iconHolder {
    width: 50px;
    margin-right: 20px;
    margin-left: 10px;
  }

  .icon {
    width: 45px;
  }

  .iconSpacer {
    width: 90px;
  }

  .actionItemHolder {
    left: 70px;
  }


}

@media screen and (min-width: 400px) {
  .weekHolder {
    width: 93%;
  }

  .actionItemHolder {
    left: 80px;
  }

  .icon {
    width: 55px;
  }

}



@media screen and (min-width: 750px) {

  .iconHolder {
    margin-right: 35px;
  }
  .icon {
    width: 55px;
  }

  .weekHolder {
    width: 80%;
  }
  

  .itemWrapper {
    padding: 20px;
  }

  #weeklyResultList {
    margin-bottom: 50px;
  }
  


}


</style>