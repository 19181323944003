<template>
  <v-app id="vApp">
    <div class="routinePage">
      <div class="contentWrapper">


          <action-wake v-if="showActionWake" :routine-template-id="routineTemplateId" />

        

          <div v-if="showRequestForm">
            <div classs="container">
              <h1>Request Routine</h1>
              <br>
              <br>

              <v-card
                class="mx-auto"
                max-width="800"
              >
                <v-form v-model="isFormValid">
                  <div class="formContainer">
                    <v-text-field
                      v-bind:key="'routineWhat_key'"
                      ref="routineWhat_ref"
                      :rules="textRules"
                      label="What to routine:"
                      clearable
                      type="text"
                      v-model="routineRequestTerm">
                    </v-text-field>
                    <v-textarea
                      v-bind:key="'routineDesc_key'"
                      ref="routineDesc_ref"
                      :rules="textRules"
                      label="Describe this routine"
                      clearable
                      type="text"
                      v-model="routineRequestDesc">
                    </v-textarea>
                    <br>
                    <br>

                    
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text :disabled="!isFormValid" @click="handleRoutineRequest">Request Routine</v-btn>
                    </v-card-actions>
                  </div>
                </v-form>
              </v-card>

            </div>
          </div>
          <div class="searchHolder" v-if="showSearch">
            <RoutineSearch 
              :parent-view="currentView"
              :search-string="searchString"
               />
          </div>
          <br>
          <br>
          <span style="font-size: 1.4em">
            <routine-category-cloud v-if="!showRequestForm"/>
          </span>
          <br>
          <br>
          

          
          <!-- 
          <div class="search_wrapper" v-if="showSearch">
            <h1>Search Routines</h1>
            <br />
            <div class="searchFieldWrapper">
              <v-text-field
                type="text"
                clearable
                counter="100"
                v-on:keyup="myFunction"
                v-on:keydown="initSearchPanel"
                label="What do you want to do?"
                autofocus
                id="searchRoutines"
                ref="searchBox"
                v-bind:key="'searchRoutines'"
                v-model="searchString"
              ></v-text-field>
            </div>
            
            <ul id="search_routineList"  v-if="showSearchPanel">
              <li v-for="(routine, index) in routineList" v-bind:key="'searchResult'+index" :id="'searchResult'+index">
                <div class="itemWrapper">
                  <div class="routineSearchItem" v-on:click="expandDetail({routine:routine, index:index})">
                      <div class="searchTitle">
                        <span>{{ (routine.rt) ? routine.rt : "null"}} </span>
                      </div>
                      <div class="daysOfWeek">
                        <div v-for="(day, indx) in routine.s" v-bind:key="indx"  class="daysOfWeekBar">
                          <span class="dayBubbleUnset" v-if="day == ''">
                            {{ daysOfWeekArray[indx] }}
                          </span>
                          <span class="dayBubbleSet" v-else>
                            {{ daysOfWeekArray[indx] }}
                          </span> 
                        </div>
                      </div> 
                      
                      
                      <span class="noSee">{{ (routine.rd) ? routine.rd : ""}}</span>
                      <div class="expandChevron">
                        <i v-if="!routineSearchItemStatusArray[index]" class="fas fa-chevron-down"></i>
                        <i v-else class="fas fa-chevron-up"></i>
                      </div>
                  </div>
                  

                  <transition name="fade">
                    
                    <div class="routineSearchItemDetail" :id="'routineSearchItemDetail'+index" v-if="routineSearchItemStatusArray[index]">
                      
                      
                      <div class="routineDetailWrapper">
                        <div class="routineDesc">
                          

                        </div>
                        <div class="detailsHolder">
                          
                          <div class="leftSide">
                            <div class="daySchedule" v-for="(schedule, key) in routineSchedules[index]" v-bind:key="key">
                              <div class="dayLine">
                                {{ schedule.day }}
                              </div>
                              <div class="scheduleLine" v-for="(session, idx) in schedule.sessions" v-bind:key="idx">
                                {{ ++idx +": "+session }}
                              </div>
                            </div>
                          </div>
                          <div class="rightSide">
                            <v-btn color="black" @click="addRoutine(routine)" >
                              <span style="color:white;">Add to Routine </span>
                            </v-btn>
                            

                          </div>
                        </div>
                      </div>





                        
                      
                    </div>
                  </transition>
                </div>
                 <router-link :to="'/category/' + item.mc">   uncomment to enable production version
                <router-link :to="'/evolve/'+item.mc">
                  <b>{{ item.mc }}</b>&nbsp;&nbsp;<i class="fas fa-arrow-right fa-xs"></i>&nbsp;&nbsp;&nbsp;<span class="ends" v-for="(i, k) in item.re" v-bind:key="k"> {{ i }}&nbsp;&nbsp;&nbsp;</span>
                </router-link>
              
              </li>
            </ul>
          </div>
          -->





        

      </div>
      <VueFooter />
    </div>
  </v-app>
</template>
<script>
  import '@/plugins/vuetify'
  //import experienceModule from '@/store/experience'
  //import categoryModule from '@/store/category'
  import userModule from '@/store/user'
  import signinModule from '@/store/signin'
  import routineModule from '@/store/routine'
  import RoutineSearch from '@/components/RoutineSearch'
  import ActionWake from '@/components/ActionWake'
  // import RoutineSearchOverlay from '@/components/RoutineSearchOverlay'
  import VueFooter from '@/components/VueFooter'
  import settingsModule from '@/store/settings'
  import Worker from "worker-loader!@/worker";
  import RoutineCategoryCloud from '../components/RoutineCategoryCloud.vue'
  //import ViewEditRoutine from '@/components/ViewEditRoutine'
  //import ListRoutines from '@/components/ListRoutines'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: "routine",
    components: {
      // RoutineOverlay,
      VueFooter,
      // RoutineSearchOverlay,
      RoutineSearch,
      RoutineCategoryCloud,
      ActionWake
      //ViewEditRoutine,
      //ListRoutines
    },
    data () {
      return {
        //category: null,
        //parentCategory: null,
        //contextCategory: null,
        //specificityLevel: null,


        //overlayType: null,
        //overlayCategory: null,
        //overlayContextCategory: null,
        //overlayParentCategory: null,
        //overlaySpecificityLevel: null,
        //overlayActionMeanCategory: null,
        routineType: null,
        routineTemplateId: null,
        showActionWake: false,
        currentView: null,


        showSearchPanel: false,
        routineList: [],
        searchString: null,
      


        showSearch: true,
        showRequestForm: false,
        routineRequestTerm: null,
        isFormValid: false,
        routineRequestDesc: null,
        textRules: [
          (v) => !!v || 'Input is required',
          (v) => (v && v.length <= 100) || 'Max 100 characters'
        ],




      }
    },
    computed: {
      ...mapGetters('auth', [
        'profile',
        'loggedIn',
        'role'
      ]),
      ...mapGetters('routine', [
        'agendaItems',
      ]),

      

      
    },
    watch: {
      
    },
    methods: {
      ...mapActions('signin', [
        'autoSignIn'
      ]),
      ...mapActions('search', [
        'setShowSearch',
        'setShowSearchIcon',
      ]),

      ...mapActions('routine', [
        //'addRoutineToUser',
        'saveRequest',
      ]),
      async handleRoutineRequest () {
        if(this.routineRequestTerm && this.routineRequestDesc) {
          var obj = {
            'routineRequestTerm': this.routineRequestTerm,
            'routineRequestDesc': this.routineRequestDesc,
          }
          await this.saveRequest(obj)
          this.$router.push("/")
        }
      },
      
   
     

      
      async fetchTheData () {
        return new Promise((resolve) => {
          //first try to fetch from cache

          //use a web worker
          const workerA = new Worker()
          workerA.postMessage({cmd: "getActiveRoutines"})
          workerA.onmessage = e => {
            var routineList = JSON.parse(e.data.payload)
            for (var key  in routineList) {
              this.routineList.push(routineList[key])
              this.routineSearchItemStatusArray.push(false)
            } 
          }

          resolve()
        })
      },
      


      
    },
    beforeCreate () {
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.routine) this.$store.registerModule('routine', routineModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      //if(!this.$store.state.tip) this.$store.registerModule('tip', tipModule)
      //if(!this.$store.state.cms) this.$store.registerModule('cms', cmsModule)
      //if(!this.$store.state.category) this.$store.registerModule('category', categoryModule)

      
    },
    async created () {
      //gtag('config', 'G-H9RD8W4MLR', {'page_path': '/routines'});
      this.setShowSearchIcon(false)
      await this.autoSignIn()
      //var catInUrl  = this.$route.params.catName

      //await this.fetchTheData();
      //this.initializeStatuses()

      var command = (this.$route.params.command) ? this.$route.params.command : null
      var type = (this.$route.params.type) ? this.$route.params.type : null
      var resourceId = (this.$route.params.resourceId) ? this.$route.params.resourceId : null

      if(resourceId) {
        resourceId = resourceId.replace(/-/g, ' ');   //resource could be
      }
      
      if(type) {
        type = type.replace(/_/g, ' ')
        type = type.replace(/-/g, ' ')
      }

      if(command) {
        switch(command) {


          case "wake":   //Get the details of a routine template

            this.routineTemplateId = type
            this.showActionWake = true
            this.currentView = 'RoutineDetail'
            break;



          case "request":
            this.routineRequestTerm = type
            this.showRequestForm = true
            this.showSearch = false
            this.currentView = 'RoutineRequest'
            
            break;


          case "search":
            this.searchString = resourceId
            this.currentView = 'RoutineSearch'

            break;

          default:

            this.currentView = 'Routine'

            break;
        }
      }else{
        this.currentView = 'Routine'
      }
      



    },

    mounted () {
      
    },
    updated () {
      
    },
    
  }
</script>
<style scoped>
#vApp {
 background-color: transparent;
}

.formContainer {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}


.contentWrapper {
  background-color: white;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  min-height: 1240px; /*this setting important to prevent double scrollbars*/
}

.searchHolder {
  background-color: transparent;
  display: block;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

/*
.leftRail {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  background-color: transparent;
  display: none;
  min-height: 1240px;  

}
*/

.middleRail {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  background-color: transparent;
  margin: 0 auto;
  min-height: 1240px; /*this setting important to prevent double scrollbars*/
  /*text-align: center;*/
}
/*
.rightRail {
  vertical-align: top;
  box-sizing: border-box;
  display: inline-block;
  background-color: transparent;
  display: none;
  min-height: 1240px; 
}
*/

.fade-enter-active, .fade-leave-active {
  transition: all .1s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-10%);
}

.search_wrapper {
  width: 100%;
  background-color: white;
  

}
.searchFieldWrapper {
  margin: 0 auto;
  padding: 20px;
  max-width: 1100px;
  
}
#search_routineList  {
  margin-top: 20px;
  list-style: none;
  padding-left: 0;
  max-width: 1100px;
}
#search_routineList > li {
  width: 100%;
  border-top: 1px solid rgb(128, 128, 128);
  
  background-color: #e0e0e0;
}
.routineSearchItem {
  font-size: 1.5em;
  font-weight: 600;
  text-align: left;
  background-color: transparent;
  position: relative;
}
.expandChevron {
  position: absolute;
  top: 2px;
  right: -7px;
  cursor: pointer;
}
.itemWrapper {
  padding: 15px;
}

.routineDetailWrapper {
  background-color: white;
  padding: 10px;
  margin-top: 20px;
}
.routineDesc {
  background-color: transparent;
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 10px;
}
.detailsHolder {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  max-width: 1000px;
}
.leftSide {
  background-color: transparent;
  position: relative;
  display: inline-block;
  padding: 10px;
}
.daySchedule {
  font-size: 1.3em;
  padding: 2px;
  text-align: left;
  background-color: transparent;
  max-width: 280px;
  margin: 0 auto;
}
.dayLine {
  border-bottom: 1px solid #cccccc;
  font-weight: 600;
  color:rgb(128, 128, 128);
}
.scheduleLine {
  font-weight: 300;
  padding-left: 20px;
}
.rightSide {
  background-color: transparent;
  position: relative;
  display: inline-block;
  padding: 10px;
}



/* Search Listings */
.searchTitle {
  font-size: .9em;
  width: 220px;
  cursor: pointer;
}
.dayBubbleSet {
  display: inline-block;
  background-color: rgb(158, 158, 158);
  color: white;
  padding: 2px;
  font-size: .45em;
  border-radius: 4px;
  margin: 1px;
  width: 18px;
  text-align: center;
}
.dayBubbleUnset {
  color:  rgb(122, 122, 122);;
  display: inline-block;
  padding: 2px;
  font-size: .45em;
  margin: 1px;
  width: 18px;
  text-align: center;
}
.daysOfWeekBar {
  display: inline-block;
}

.daysOfWeek {
  display: inline-block;
  position: absolute;
  top: -3px;
  right: 26px;
}


.tagChip2 {
  display: inline-block;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: rgb(158, 158, 158);
  color: black;
  border-radius: 4px;
  font-size: 1.15em;
  margin: 5px;
  font-weight: 500;
}
.routineTags2 {
  background-color: transparent;
  padding: 5px;
  margin-bottom: 20px;
  font-family: 'Quicksand', sans-serif;
    font-size: 1.5em;
    font-weight: 400;

}



/*
@media screen and (min-width: 820px) {
  .middleRail {
    width: 76%;
  }
  .rightRail {
    display: inline-block;
    width: 24%;
  }
  .searchTitle {
    width: 500px;
    font-size: 1.1em;
  }
}


@media screen and (min-width: 1250px) {
  .leftRail {
    display: inline-block;
    width: 18%;
  }
  .middleRail {
    width: 64%;
  }
  .rightRail {
    display: inline-block;
    width: 18%;
  }

}

@media screen and (min-width: 1300px) {
  .leftRail {
    width: 18%;
  }
  .middleRail {
    width: 64%;
  }
  .rightRail {
    width: 18%;
  }
}


@media screen and (min-width: 1400px) {
  .leftRail {
    width: 17%;
  }
  .middleRail {
    width: 66%;
  }
  .rightRail {
    width: 17%;
  }
}





@media screen and (min-width: 1600px) {
  .leftRail {
    width: 22%;
  }
  .middleRail {
    width: 56%;
  }
  .rightRail {
    width: 22%;
  }
}
*/

</style>